<button class="trigger" [disabled]="disabledSignal$()" (click)="op.toggle($event)">
	<span class="sl-typography-link-action trigger-label" [matTooltip]="value.type | tiaFullRunPolicyDescription:value.daysBetweenFullRuns">{{ label }}</span>
	<span class="trigger-icon" [class.open]="isOpen"></span>
</button>

<p-overlayPanel #op
				appendTo="body"
				styleClass="panel"
				[showCloseIcon]="false"
				[dismissable]="true"
				(onHide)="isOpen = false"
				(onShow)="isOpen = true">
	<ng-template pTemplate>
		<ul class="p-dropdown-items">
			<li class="p-dropdown-item p-ripple" [class.p-highlight]="value?.type === TiaCiPolicyType.Daily"
				(click)="selectPolicy(TiaCiPolicyType.Daily)">
				<div class="dropdown-item-radio"></div>
				<div class="sl-flexbox">Every day, on the first build reported after 00:00 (UTC)</div>
			</li>
			<li class="p-dropdown-item p-ripple" [class.p-highlight]="value?.type === TiaManualPolicyType.Weekly"
				(click)="selectPolicy(TiaManualPolicyType.Weekly)">
				<div class="dropdown-item-radio"></div>
				<div class="sl-flexbox">Every week, on the first build reported after Monday at 00:00 (UTC)</div>
			</li>
			<li class="p-dropdown-item p-ripple" [class.p-highlight]="value?.type === TiaManualPolicyType.Monthly"
				(click)="selectPolicy(TiaManualPolicyType.Monthly)">
				<div class="dropdown-item-radio"></div>
				<div class="sl-flexbox">Every month, on the first build reported after the 1st at 00:00 (UTC)</div>
			</li>
			<li class="p-dropdown-item p-ripple" [class.p-highlight]="value?.type === TiaManualPolicyType.EveryXMonth"
				(click)="selectPolicyEveryXTime(TiaManualPolicyType.EveryXMonth)">
				<div class="dropdown-item-radio"></div>
				<div class="sl-flexbox-inline-row every-x-test-runs-item">
					Every
					<input
						class="p-inputtext remove-number-input-arrows every-x-test-runs-input"
						type="number"
						min="1"
						max="99"
						step="1"
						(change)="selectPolicyEveryXTime(TiaManualPolicyType.EveryXMonth)"
						[(ngModel)]="daysBetweenFullRunsMonthInputValue"
						(keydown.enter)="selectPolicyEveryXTime(TiaManualPolicyType.EveryXMonth)"
						(click)="$event.stopPropagation()" />
					month
				</div>
			</li>
			<li class="p-dropdown-item p-ripple"
				[class.p-highlight]="value?.type === TiaManualPolicyType.EveryXTestRuns"
				(click)="selectPolicyEveryXTime(TiaManualPolicyType.EveryXTestRuns)">
				<div class="dropdown-item-radio"></div>
				<div class="sl-flexbox-inline-row every-x-test-runs-item">
					Every
					<input
						class="p-inputtext remove-number-input-arrows every-x-test-runs-input"
						type="number"
						min="1"
						max="99"
						step="1"
						(change)="selectPolicyEveryXTime(TiaManualPolicyType.EveryXTestRuns)"
						[(ngModel)]="daysBetweenFullRunsTestRunsInputValue"
						(keydown.enter)="selectPolicyEveryXTime(TiaManualPolicyType.EveryXTestRuns)"
						(click)="$event.stopPropagation()" />
					builds (for builds that the selected test stage has run against)
				</div>
			</li>
		</ul>
	</ng-template>
</p-overlayPanel>
