<sl-dialog [title]="dialogTitle">
    <ng-container content>
        <div class="sl-flexbox-column sl-flexbox-gap-4">
            <div class="sl-flexbox-column sl-flexbox-gap-16">
                <sl-breadcrumbs [items$]="breadcrumbsItems$()" [size$]="breadcrumbSizeOption.Large"></sl-breadcrumbs>
                <!-- <div class="sl-flexbox-row sl-flexbox-align-center sl-flexbox-gap-4 sl-flexbox-justify-end sl-color-text-secondary">
                    <mat-icon class="material-symbols-outlined mat-icon-16">info_outline</mat-icon>
                    <div class="">Note: This table has a horizontal scroll!</div>
                </div> -->
            </div>
            <sl-tia-v2-settings-table [tiaSettingsRequestParams$]="dialogData" (testSelectionStatusChanged$)="onTestSelectionStatusChanged()"></sl-tia-v2-settings-table>
        </div>
    </ng-container>
</sl-dialog>